<template>
  <div
    class="row mb-2"
  >
    <div 
      :class="widthClass"
    >
      <dropdown
        v-if="!disabled"
        label="Gebruiker toevoegen"
        :options="userOptions" 
        @update:value="addUser"
      />
    </div>
  </div>

  <loading-spinner 
    v-if="loading"
    text-align="center"
  />
  
  <div 
    v-if="!loading"
    class="row"
  >
    <div
      v-for="user in displayedUsers" 
      :key="user.id" 
      class="my-3"
      :class="userWidth"
    >
      <user-card
        :user="user"
        @deleted="markUserAsDeleted"
      />
    </div>

    <list-cutoff
      v-if="displayedUserCount < filteredUsers.length"
      :show-all-button-text="`Toon alle gebruikers (${filteredUsers.length})`"
      @show-all-button-pressed="onShowAllButtonPressed"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/UI/Dropdown.vue';
import { getAll as getAllUsers } from '@/api/providers/users';

import UserCard from './UserCard.vue';

import { toRaw } from 'vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import ListCutoff from '@/components/UI/ListCutoff.vue';

export default {
  components: {
    Dropdown,
    ListCutoff,
    LoadingSpinner,
    UserCard
  },

  props: {
    userIds: { type: Array, required: true },
    showUpdate: { type: Boolean, default: true },
    addUserCallback: { type: Function, required: true },
    removeUserCallback: { type: Function, required: true },
    fullWidth: { type: Boolean, required: false },
    disabled: { type: Boolean, default: false },
    initialMaxUserCount: { type: Number, default: Number.MAX_SAFE_INTEGER },
  },

  data() {
    return {
      userOptions: [],
      selectedUsers: [],
      loading: true,
      displayedUserCount: this.initialMaxUserCount,
    };
  },

  computed: {
    displayedUsers() {
      return this.filteredUsers.slice(0, this.displayedUserCount);
    },

    filteredUsers() {
      return this.selectedUsers.filter(u => !u.isDeleted);
    },
    
    widthClass() { 
      return this.fullWidth
        ? 'col-12'
        : 'col-4';
    },
    userWidth() {
      return this.fullWidth 
        ? 'col-sm-12'
        : 'col-sm-4';
    }
  },

  async mounted() {
    await this.getUsers();
    this.initializeSelectedUsers();
    this.loading = false;
  },

  methods: {
    async getUsers() {
      try {
        let users = await getAllUsers();
        if (users == null) users = [];

        this.userOptions = users.map(u => {
          return {
            value: u.id,
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName ?? ''}`,
            image: u.profilePicture
          }
        });
      }
      catch {
        this.userOptions = [];
      }
    },

    initializeSelectedUsers() {
      const options = this.userOptions.filter(o => 
        (this.userIds ?? []).includes(Number(o.value))
      ).map(o => toRaw(o));

      for (const option of options) {
        this.selectedUsers.push({
          id: option.value,
          name: option.label,
          image: option.image
        });
      }
    },

    addUser(id) {
      if (this.selectedUsers.find(u => u.id === id) != null) return;

      let option = this.userOptions.find(o => o.value === id);
      if (option == null) return;

      this.selectedUsers.push({
        id: option.value,
        name: option.label,
        image: option.image,
        isNew: true
      });
    },

    async submit(entityId) {
      await this.submitAddedUsers(entityId);
      await this.submitRemovedUsers(entityId);
    },

    async submitAddedUsers(entityId) {
      const userIds = this.selectedUsers
          .filter(u => u.isNew && !u.isDeleted)
          .map(u => u.id);

      await this.addUserCallback(entityId, userIds);
    },

    async submitRemovedUsers(entityId) {
      let userIds = this.selectedUsers
          .filter(u => u.isDeleted && !u.isNew)
          .map(u => u.id);

      await this.removeUserCallback(
        entityId,
        userIds
      );
    },

    markUserAsDeleted(id) {
      const userIndex = this.selectedUsers.findIndex(u => u.id === id);
      if (userIndex < 0) return;

      this.selectedUsers[userIndex] = { 
        ...this.selectedUsers[userIndex], 
        isDeleted: true
      };
    },

    onShowAllButtonPressed() {
      this.displayedUserCount = Number.MAX_SAFE_INTEGER
    }
  },
}
</script>