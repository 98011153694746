<template>
  <div class="allow-reactions d-flex align-items-center justify-content-between mt-2">
    <p class="my-0">Plaatsen van reacties toegestaan?</p>
    <material-switch v-model="allowReactions" />
  </div>
</template>

<script>
import MaterialSwitch from '@/components/MaterialSwitch.vue';

export default {
  components: {
    MaterialSwitch,
  },
  props: {
    modelValue: {
      type: Boolean
    },
  },

  emits: [
    'update:modelValue'
  ],

  computed: {
    allowReactions: {
      get() { return !this.modelValue; },
      set(value) { this.$emit('update:modelValue', !value); }
    }
  }
}
</script>